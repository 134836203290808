<template>
  <ModuleStep :index="7" :prev-step="previous">
    <AdiForm
      v-slot="{ loading }"
      :hide-submit="!isComplete"
      submit-icon="arrow-right"
      @submit.prevent="onSubmit"
    >
      <FormSection>
        <Headline :level="1">
          {{ $t('pages.poa.medical.preferences.headline') }}
        </Headline>
        <template v-if="['nsw', 'vic', 'tas'].includes(userState)">
          <p class="mb-4">
            {{ $t('pages.poa.medical.preferences.preferencesImportant') }}
          </p>
        </template>
        <template v-if="['qld', 'nt'].includes(userState)">
          <p class="mb-4">
            {{ $t('pages.poa.medical.preferences.preferencesNotBinding') }}
          </p>
        </template>
        <template v-if="['wa'].includes(userState)">
          <p class="mb-4">
            {{ $t('pages.poa.medical.preferences.attorneyPower') }}
          </p>
        </template>
        <template v-if="['sa'].includes(userState)">
          <p class="mb-4">
            {{ $t('pages.poa.medical.preferences.decisionMakerInfo1') }}
          </p>
          <p class="mb-4">
            {{ $t('pages.poa.medical.preferences.decisionMakerInfo2') }}
          </p>
        </template>
        <p class="mb-4">
          {{ $t('pages.poa.medical.preferences.exampleOfPreferences') }}
        </p>
        <LargeRadioButtons
          id="preferences"
          v-model="poaMedicalMeta.has_preferences"
          layout="column"
          :options="[
            {
              label: $t('forms.labels.yes'),
              value: true,
            },
            {
              label: $t('forms.labels.no'),
              value: false,
            },
          ]"
          @input="onChange"
        />
        <div v-if="poaMedicalMeta.has_preferences">
          <TextArea
            id="preferences"
            v-model="poaMedicalMeta.preferences"
            :disabled="loading"
            maxlength="2500"
            :placeholder="$t('forms.placeholders.itIsMyPreference')"
          />
        </div>
      </FormSection>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/medical';
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import FormSection from '@/components/FormSection';
import LargeRadioButtons from '@/components/LargeRadioButtons';
import TextArea from '@/components/TextArea';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaMedicalPreferences',
  components: {
    AdiForm,
    ModuleStep,
    FormSection,
    LargeRadioButtons,
    TextArea,
    Headline,
  },
  mixins: [user, will],
  apollo: {
    poaMedicalMeta: {
      ...queries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      poaMedicalMeta: {
        has_preferences: undefined,
        preferences: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
      });
    },
    isComplete() {
      return (
        this.poaMedicalMeta.has_preferences === false ||
        (this.poaMedicalMeta.has_preferences === true &&
          this.poaMedicalMeta.preferences?.length > 0)
      );
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
  },
  methods: {
    onChange(value) {
      if (value === false) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMetas(this.poaId, [
          {
            key: 'has_preferences',
            value: this.poaMedicalMeta.has_preferences ? 'true' : 'false',
            category: 'MEDICAL',
          },
          {
            key: 'preferences',
            value: this.poaMedicalMeta.has_preferences
              ? this.poaMedicalMeta.preferences
              : null,
            category: 'MEDICAL',
          },
        ])
      );

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module POA medical completed',
      });
      const path = this.localePath(this.next);
      await this.$router.push({ path });
    },
  },
};
</script>
